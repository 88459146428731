<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="订单编号：">
        <el-input size="small" v-model="ruleForm.ordersn" placeholder="请输入订单编号"></el-input>
      </el-form-item>
      <el-form-item label="会员姓名：">
        <el-input size="small" v-model="ruleForm.nick_name" placeholder="请输入会员姓名"></el-input>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker size="small" v-model="ruleForm.time" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input size="small" v-model="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="数据来源：">
        <el-select size="small" v-model="ruleForm.cate_id" placeholder="请选择">
          <el-option v-for="item in activeTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属活动：">
        <el-input size="small" v-model="ruleForm.title" placeholder="请输入活动名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="ordersn" label="订单编号" align="center"></el-table-column>
      <el-table-column prop="nick_name" label="会员姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
      <el-table-column label="数据来源" align="center">
        <template v-slot="{ row }">
          {{ row.comfrom === 1 ? '后台上传' : '小程序上传' }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="所属活动" align="center"></el-table-column>
      <el-table-column label="活动价格" align="center">
        <template v-slot="{ row }">
          ￥{{ row.price || 0}}
        </template>
      </el-table-column>
      <el-table-column label="会员折扣" align="center">
        <template v-slot="{ row }">
          {{ row.discount || '--'}}
        </template>
      </el-table-column>
      <el-table-column label="折后价格" align="center">
        <template v-slot="{ row }">
          ￥{{ parseFloat(Number(row.price) * Number(row.discount) / 10).toFixed(2)}}
        </template>
      </el-table-column>
      <el-table-column label="支付金额" align="center">
        <template v-slot="{ row }">
          ￥{{ row.payprice || 0}}
        </template>
      </el-table-column>
      <el-table-column label="支付积分" align="center">
        <template v-slot="{ row }">
          {{ row.integral || 0}}
        </template>
      </el-table-column>
      <el-table-column label="支付时间" align="center">
        <template v-slot="{ row }">
          {{ row.pay_time ? getDateformat(row.pay_time) :'--'}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{row}">
          <el-button type="text" @click="lookSign(row)">查看报名</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="dataTotal" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js'
export default {
  components: {
    Paging
  },
  data () {
    return {
      getDateformat,
      ruleForm: {
        page: 1,
        rows: 10,
        ordersn: '',
        nick_name: '',
        time: [],
        cate_id: 0,
        title: '',
        mobile: ''
      },
      dataList: [],
      dataTotal: 0,
      activeTypeList: [
        { id: 0, name: '全部' },
        { id: 1, name: '后台上传' },
        { id: 2, name: '小程序上传' },
      ]
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    lookSign (data) {
      let name = data.nick_name || ''
      this.$router.push('/extension/customform/datalist?name=' + name)
    },
    updateData (val, status) {
      if (status == 0) {
        this.ruleForm.rows = val;
      } else {
        this.ruleForm.page = val;
      }
      this.getDataList()
    },
    clearSearch () {
      this.ruleForm = {
        page: 1,
        rows: 10,
        ordersn: '',
        nick_name: '',
        time: [],
        cate_id: 0,
        title: '',
        mobile: ''
      }
      this.getDataList()
    },
    getDataList (style) {
      if (style) this.ruleForm.page = 1
      let obj = {
        page: this.ruleForm.page,
        rows: this.ruleForm.rows
      }
      if (this.ruleForm.ordersn) obj.ordersn = this.ruleForm.ordersn
      if (this.ruleForm.nick_name) obj.nick_name = this.ruleForm.nick_name
      if (this.ruleForm.cate_id) obj.cate_id = this.ruleForm.cate_id
      if (this.ruleForm.title) obj.title = this.ruleForm.title
      if (this.ruleForm.mobile) obj.mobile = this.ruleForm.mobile
      if (this.ruleForm.time?.length) {
        obj.start_time = Math.ceil(this.ruleForm.time[0].getTime() / 1000)
        obj.end_time = Math.ceil(this.ruleForm.time[1].getTime() / 1000)
        obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time
      }
      this.$axios.post(this.$api.gateway.activity.getOrderList, obj).then(res => {
        if (res.code == 0) {
          this.dataList = res.result.list
          this.dataTotal = res.result.total
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>